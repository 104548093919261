<template>
  <b-modal
    id="request-dashboard-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @hidden="hidden"
    @show="getAdvertisers"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ $t('advantages_of_using_the_dashboard') }}</span>
      </h2>
      <p>{{ $t('some_of_the_advantages_of_using_the_dashboard') }}</p>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-3 default-form">
      <form class="default-form" @submit.prevent="">
        <div class="mt-3 text-center">
          {{ $t('advantages_dashboard') }}
        </div>
        <div class="mt-4 mb-3">
          <h6 class="text-left">{{ $t('request_dashboard_select_advertiser') }}</h6>
        </div>
        <!-- ADVERTISER -->
        <template class="form-group">
          <label class="form-label fw-bold">
            {{ $t('advertiser') }}*
          </label>
          <div v-if="advertisersLoader">
            <Loader
              :size="'small'"
              :align="'start'"
            />
          </div>
          <template v-else>
            <div class="custom-select">
              <select
                class="form-control"
                v-model.trim="form.advertiser_id"
                @change="selectedAdvertiser"
              >
                <option disabled="true" selected="true">{{ $t('select') }}</option>
                <template v-if="advertisers.length > 0">
                  <option v-for="(lAdvertiser, index) in advertisers" :key="index" :value="lAdvertiser.id">{{ lAdvertiser.name }}</option>
                </template>
              </select>
            </div>
            <p
              class="font-size-xSmall fw-bold mt-2 error-message-card"
              v-if="selectedAdvertiser && selectedAdvertiser.status === 'ERROR'"
            >
              {{ $t('advertiser_creation_issue_access_page_try_again', {name:selectedAdvertiser.name}) }}
            </p>
          </template>
        </template> <!-- END ADVERTISER -->
        <div class="mt-4 text-center">
          <Button
            class="btn btn-primary"
            @click="submit"
            :loading="loader"
            :disabled="!form.advertiser_id"
          >
            {{ $t('request_dashboard') }}
          </Button>
        </div>
        </form>
    </div>
  </b-modal>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import DashboardsService from '@/modules/dashboards/services/dashboards-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import { mapState } from 'vuex'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'RequestDashboardModal',
  components: {
    AlertWraper,
    Button,
    Loader
  },
  data () {
    return {
      advertisers: [],
      advertisersLoader: false,
      content: null,
      content_type: 'success',
      form: {
        advertiser_id: ''
      },
      loader: false
    }
  },
  watch: {
    'form.advertiser_id' (newValue) {
      this.form.advertiser_id = newValue
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    })
  },
  methods: {
    /**
     * Get advertisers
     */
    async getAdvertisers () {
      this.advertisersLoader = true
      try {
        const advertisers = await AdvertisersService.getAdvertisers({
          companyId: this.user.company.id,
          itemsPerPage: 100
        })
        this.advertisers = advertisers.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.advertisersLoader = false
    },
    /**
     * On modal hidden
     */
    hidden () {
      this.content = null
      this.advertisers = []
    },
    /**
     * Selected advertiser
     */
    selectedAdvertiser () {
      if (!this.form.advertiser_id) return null
      return this.advertisers.find(item => {
        return item.id === this.form.advertiser_id
      })
    },
    /**
     * Submit form
     */
    async submit () {
      this.loader = true

      try {
        const response = await DashboardsService.requestDashboard({
          advertiserId: this.form.advertiser_id
        })
        this.content = response.message

        const notification = {
          notification: {
            type: 'success',
            content: this.$t('request_dashboard_success_message')
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.$bvModal.hide('request-dashboard-modal')
      } catch (error) {
        this.content_type = 'error'
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
